import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard.guard';
import { PreviewComponent } from './preview/preview.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'unauthorize',
    component: UnauthorizedComponent
  },
  {
    path: 'preview',
    component: PreviewComponent
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./views/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./views/analytics-dashboard/analytics-dashboard.module').then(
        (m) => m.AnalyticsDashboardModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./views/merchant/merchant.module').then((m) => m.MerchantModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'members',
    loadChildren: () =>
      import('./views/members/member.module').then((m) => m.MemberModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'star',
    loadChildren: () =>
      import('./views/add-star/add-star.module').then((m) => m.AddStarModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rules',
    loadChildren: () =>
      import('./views/earn-rules/earn-rules.module').then(
        (m) => m.EarnRulesModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'access-management',
    loadChildren: () =>
      import('./views/access-manager/access-manager.module').then(
        (m) => m.AccessManagerModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'bulk-points',
    loadChildren: () =>
      import('./views/bulk-points/bulk-points.module').then(
        (m) => m.BulkPointsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'tiers',
    loadChildren: () =>
      import('./views/tiers/tiers.module').then((m) => m.TiersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-logs',
    loadChildren: () =>
      import('./views/admin-actions/admin-actions.module').then(
        (m) => m.AdminAuditModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'offer',
    loadChildren: () =>
      import('./views/offers/offers.module').then((m) => m.OffersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activity-configuration',
    loadChildren: () =>
      import('./views/activity-config/activity-config.module').then(
        (m) => m.ActivityConfigModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'cohorts',
    loadChildren: () =>
      import('./views/cohorts/cohorts.module').then((m) => m.CohortsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'webhook',
    loadChildren: () =>
      import('./views/webhook/webhook.module').then((m) => m.WebhookModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customize',
    loadChildren: () =>
      import('./views/customize/customize.module').then(
        (m) => m.CustomizeModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./views/roles/roles.module').then((m) => m.RolesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./views/subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'approvals',
    loadChildren: () =>
      import('./views/approvals/approvals.module').then(
        (m) => m.ApprovalsModule
      ),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
